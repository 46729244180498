<script>
  import { Link } from "svelte-routing";
</script>

<main>
  <div class="container">
    <div class="vertical-line-left"></div>
    <div class="horizontal-line"></div>
  </div>
  <header>
    <div class="menu">
      <!-- svelte-ignore missing-declaration -->
      <Link to="/">
        <img src="/img/logo_10110.svg" alt="Logo 10110" class="logo" />
      </Link>
    </div>

    <div class="title">
      <h1 class="name">Where Persian Art Meets the Digital World!</h1>
    </div>
  </header>
  <section>
    <p class="intro">
      Welcome! If you've arrived here, you're likely ready to elevate your
      online presence — and you're in the right place. I’ve registered this
      domain with a clear purpose: to help Persian art and culture make a
      lasting impact on the digital landscape.
    </p>
    <div class="code-container">
      <p>
        Whether you’re an artist, a filmmaker, a writer, or simply someone who
        values cultural history, my mission is to help you showcase your
        passion. Through custom web design and the creation of online spaces
        that bring your work to life, I am committed to ensuring that your
        contribution is not just remembered — it’s recognized and respected
        worldwide.
      </p>
    </div>

    <p>
      Together, we can make Persian art and culture a permanent fixture in the
      digital world. Ready to bring the beauty of history into the future? Let’s
      create something unforgettable.
    </p>
    <p>
      You can contact me at <code>&lt;info at 10110 dot com&gt;</code>
      for work-related questions.
    </p>
  </section>

  <div class="version">
    <a href="https://10110.dev" target="_blank">v.1.0</a>
  </div>
</main>

<style>
  :global(body) {
    margin: 0;
    padding: 0;
    background-color: var(--backgroundColor);
    color: var(--textColor);
    font-size: 1.2em;
    font-family: var(--sansSerif);
    line-height: 1.62em;
    word-wrap: break-word;
  }

  header {
    margin-bottom: 3rem;
  }

  .menu {
    display: flex;
    justify-content: space-between;
  }

  .guide {
    width: 2rem;
    margin-bottom: 1rem;
  }

  main {
    max-width: 45vw;
    margin: 50px auto 0 auto;
  }

  section {
    margin-top: 20px;
  }

  .title {
    display: flex;
    justify-content: space-between;
  }

  .name {
    color: var(--mutedTextColor);
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
  }

  .intro {
    color: var(--textColor);
    margin: 0 0 10px 0;
  }

  .logo {
    width: 10rem;
    margin-bottom: 1rem;
  }

  .desc {
    font-size: 0.75em;
    line-height: 1.5;
    color: var(--textColor);
  }

  .container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
  }

  .vertical-line-left {
    position: relative;
    top: 0;
    left: 25vw;
    width: 1px;
    height: 100%;
    background-color: var(--lineColor);
    z-index: -1;
  }

  .horizontal-line {
    position: absolute;
    top: 20vh;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--lineColor);
    z-index: -1;
  }

  .code-container {
    display: flex;
    max-width: 45vw;
    padding: 0 20px;
    margin: 20px auto;
    background-color: var(--frameBackgroundColor);
    position: relative;
    overflow: hidden;
    border-radius: 20px;
  }

  .code-container {
    font-family: var(--loRes);
    font-weight: 400;
  }

  code {
    font-family: var(--loRes);
    font-weight: 400;
  }

  .version {
    text-align: right;
    font-size: 0.6em;
  }

  .version a {
    font-size: 0.6rem;
    color: var(--textColor);
    text-decoration: none;
    background-color: #2d3947;
    padding: 3px 7px;
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    main {
      max-width: 80vw;
      margin: 50px auto;
    }
    .code-container {
      max-width: 80vw;
    }
    .vertical-line-left {
      left: 6vw;
    }
  }
</style>
