<script>
  import { Router, Route } from "svelte-routing";
  import Home from "./Home.svelte";
  import Guide from "./Guide.svelte";
</script>

<main>
  <Router>
    <Route path="/" component={Home} />
    <Route path="/guide" component={Guide} />
  </Router>
</main>

<style>
  :global(body) {
    margin: 0;
    padding: 0;
    background-color: var(--backgroundColor);
    color: var(--textColor);
    font-size: 1.2em;
    font-family: var(--sansSerif);
    line-height: 1.62em;
    word-wrap: break-word;
  }

  main {
    max-width: 45vw;
    margin: 50px auto 0 auto;
  }

  @media (max-width: 768px) {
    main {
      max-width: 80vw;
      margin: 50px auto;
    }
  }
</style>
